// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-academy-js": () => import("./../../../src/pages/academy.js" /* webpackChunkName: "component---src-pages-academy-js" */),
  "component---src-pages-advance-program-js": () => import("./../../../src/pages/advance-program.js" /* webpackChunkName: "component---src-pages-advance-program-js" */),
  "component---src-pages-blog-detail-js": () => import("./../../../src/pages/blog-detail.js" /* webpackChunkName: "component---src-pages-blog-detail-js" */),
  "component---src-pages-blog-group-js": () => import("./../../../src/pages/blog-group.js" /* webpackChunkName: "component---src-pages-blog-group-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-covid-19-js": () => import("./../../../src/pages/covid19.js" /* webpackChunkName: "component---src-pages-covid-19-js" */),
  "component---src-pages-design-studio-js": () => import("./../../../src/pages/design-studio.js" /* webpackChunkName: "component---src-pages-design-studio-js" */),
  "component---src-pages-essential-program-js": () => import("./../../../src/pages/essential-program.js" /* webpackChunkName: "component---src-pages-essential-program-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-js": () => import("./../../../src/pages/job.js" /* webpackChunkName: "component---src-pages-job-js" */),
  "component---src-pages-jobs-details-js": () => import("./../../../src/pages/jobs-details.js" /* webpackChunkName: "component---src-pages-jobs-details-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/news-detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-products-deduklick-js": () => import("./../../../src/pages/products/deduklick.js" /* webpackChunkName: "component---src-pages-products-deduklick-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-service-design-studio-js": () => import("./../../../src/pages/service/design-studio.js" /* webpackChunkName: "component---src-pages-service-design-studio-js" */),
  "component---src-pages-service-meddev-js": () => import("./../../../src/pages/service/meddev.js" /* webpackChunkName: "component---src-pages-service-meddev-js" */),
  "component---src-pages-service-risk-analyzer-js": () => import("./../../../src/pages/service/risk-analyzer.js" /* webpackChunkName: "component---src-pages-service-risk-analyzer-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

